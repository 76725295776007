import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import ChoosePart from '../views/ChoosePart.vue'
import ChooseAcid from '../views/ChooseAcid.vue'
import ChooseResvItem from '../views/ChooseResvItem.vue'
import ChooseLaser from '../views/ChooseLaser.vue'
import ChooseMedPart from '../views/ChooseMedPart.vue'
import ChooseMedProblem from '../views/ChooseMedProblem.vue'
import ChooseMicroPlastic from '../views/ChooseMicroPlastic.vue'
import ChooseService from '../views/ChooseService.vue'
import ChooseTime from '../views/ChooseTime.vue'
import Complete from '../views/Complete.vue'

// 愛爾麗
import AirLeeStaffLogin from '../views/airlee/AirLeeStaffLogin.vue'
import AirLeeChooseClinic from '../views/airlee/AirLeeChooseClinic.vue'
import AirLeeCustomerLogin from '../views/airlee/AirLeeCustomerLogin.vue'
import AirLeeChooseService from '../views/airlee/AirLeeChooseService.vue'
import AirLeeChooseTime from '../views/airlee/AirLeeChooseTime.vue'
import AirLeeComplete from '../views/airlee/AirLeeComplete.vue'
import AirLeeChooseResvItem from '../views/airlee/AirLeeChooseResvItem.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/choose-service',
    name: 'ChooseService',
    component: ChooseService,
    meta: { auth: true }
  },
  {
    path: '/choose-resv-item',
    name: 'ChooseResvItem',
    component: ChooseResvItem,
    meta: { auth: true }
  },
  {
    path: '/choose-part',
    name: 'ChoosePart',
    component: ChoosePart,
    meta: { auth: true }
  },
  {
    path: '/choose-acid',
    name: 'ChooseAcid',
    component: ChooseAcid,
    meta: { auth: true }
  },
  {
    path: '/choose-laser',
    name: 'ChooseLaser',
    component: ChooseLaser,
    meta: { auth: true }
  },
  {
    path: '/choose-micro-plastic',
    name: 'ChooseMicroPlastic',
    component: ChooseMicroPlastic,
    meta: { auth: true }
  },
  {
    path: '/choose-med-part',
    name: 'ChooseMedPart',
    component: ChooseMedPart,
    meta: { auth: true }
  },
  {
    path: '/choose-med-problem',
    name: 'ChooseMedProblem',
    component: ChooseMedProblem,
    meta: { auth: true }
  },
  {
    path: '/choose-time',
    name: 'ChooseTime',
    component: ChooseTime,
    meta: { auth: true }
  },
  {
    path: '/complete',
    name: 'Complete',
    component: Complete,
    meta: { auth: true }
  },
  // 愛爾麗
  {
    path: '/airlee/staff/login',
    name: 'AirLeeStaffLogin',
    component: AirLeeStaffLogin
  },
  {
    path: '/airlee/choose-clinic',
    name: 'AirLeeChooseClinic',
    component: AirLeeChooseClinic,
  },
  {
    path: '/airlee/customer/login',
    name: 'AirLeeCustomerLogin',
    component: AirLeeCustomerLogin
  },
  {
    path: '/airlee/choose-service',
    name: 'AirLeeChooseService',
    component: AirLeeChooseService,
  },
  {
    path: '/airlee/choose-resv-item',
    name: 'AirLeeChooseResvItem',
    component: AirLeeChooseResvItem,
  },
  {
    path: '/airlee/choose-time',
    name: 'AirLeeChooseTime',
    component: AirLeeChooseTime,
  },
  {
    path: '/airlee/complete',
    name: 'AirLeeComplete',
    component: AirLeeComplete,
  },
  {
    path: '*',
    name: '404',
    component: Login
  },
]

const router = new VueRouter({
  routes
})

export default router
