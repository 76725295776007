<template>
  <div class="main jcc ais">
    <div class="main__block">
      <div class="title">選擇預約項目</div>
      <div class="flex f-col jcs ais w-100">
        <div class="part__body">
          <div v-for="resvItem in booking.children" :key="`resvItem_${resvItem.id}`" class="choose__item square">
            <input v-model="tempResvItem" :value="resvItem" type="radio" />
            <div class="checkTag">
              <span v-html="resvItem.name"></span>
            </div>
          </div>
        </div>
        
        <div class="flex f-row jce w-100">
          <div @click="chooseTime" class="btn next">下一步</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'AirLeeChooseResvItem',
  components: {},
  data() {
    return {
      tempResvItem: ''
    };
  },
  methods: {
    checkClinic() {
      const clinicCode = this.$route.query.clinicCode;
      if (clinicCode) {
        localStorage.setItem('clinicCode', clinicCode);
      } else {
        alert('診所代碼錯誤,請重新登入！');
        this.backToLogin();
      }
    },
    backToLogin() {
      const clinicCode = this.$route.query.clinicCode;
      this.$router.push(`/airlee/staff/login?clinicCode=${clinicCode}`);
    },
    async chooseTime() {
      const clinicCode = localStorage.getItem('clinicCode');
      if (!this.tempResvItem) {
        alert('尚未選擇預約子項目！');
      } else {
        try {
          await this.$store.dispatch('airleeResvModule/setMed', { 
            data: {
              name: this.tempResvItem.name,
              booking_id_1: this.tempResvItem.parent.id,
              booking_id_2: this.tempResvItem.id,
              schlen: parseInt(this.tempResvItem.schlen),
              checkin: parseInt(this.tempResvItem.checkin)
            }, 
            clinicCode, 
          });
          await this.$store.dispatch('airleeResvModule/setTime', { data: this.needTime, clinicCode, });
          this.$router.push(`/airlee/choose-time?clinicCode=${this.$route.query.clinicCode}`);
        } catch (e) {
          const tokenError = ['無效的token', 'token錯誤', '該token已失效'];
          if (tokenError.indexOf(e) > -1) {
            alert('請重新登入');
            this.$router.push(`/airlee/staff/login?clinicCode=${clinicCode}`);
          } else if (e == 'his api not found') {
            alert('請重新登入');
            this.$router.push(`/airlee/staff/login?clinicCode=${clinicCode}`);
          } else {
            alert(e);
          }
          console.log(e);
        }
      }
    },
  },
  computed: {
    ...mapGetters('authModule', ['customerByC', 'clinicByC']),
    ...mapGetters('airleeResvModule', ['roomByC', 'bookingByC']),
    customer() {
      const clinicCode = this.$route.query.clinicCode;
      return this.customerByC(clinicCode);
    },
    room() {
      const clinicCode = this.$route.query.clinicCode;
      return this.roomByC(clinicCode);
    },
    booking() {
      const clinicCode = this.$route.query.clinicCode;
      return this.bookingByC(clinicCode);
    },
    needTime() {
      let schlen = parseInt(this.tempResvItem.schlen);
      let checkin = parseInt(this.tempResvItem.checkin);
      return { schlen, checkin, };
    },
    clinic() {
      const clinicCode = this.$route.query.clinicCode;
      return this.clinicByC(clinicCode);
    },
  },
  async created() {
    await this.checkClinic();
    window.scrollTo(0, 0);
  },
};
</script>
