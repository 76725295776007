import axios from 'axios';
import https from 'https';
const httpsAgent = new https.Agent({
  rejectUnauthorized: false,
});
const instance = axios.create({
  baseURL: `${process.env.VUE_APP_APIURL}/api`,
  httpsAgent,
});

export default {
  namespaced: true,
  state: {
    test: {foo: '123', bar: 'hello'},
    resv_list: {},
    resv: {},
    note: '',
    registrations: {},
  },
  actions: {
    // setClinic(context, status) {
    //   context.commit('SET_CLINIC', status);
    // },
    setMedPart(context, status) {
      context.commit('SET_MED_PART', status);
    },
    setMedProblem(context, status) {
      context.commit('SET_MED_PROBLEM', status);
    },
    setMed(context, status) {
      context.commit('SET_MED', status);
    },
    setMedBooking(context, status) {
      context.commit('SET_MED_BOOKING', status);
    },
    setRoom(context, status) {
      context.commit('SET_ROOM', status);
    },
    setBooking(context, status) {
      context.commit('SET_BOOKING', status);
    },
    setTime(context, status) {
      context.commit('SET_TIME', status);
    },
    setNote(context, status) {
      context.commit('SET_NOTE', status);
    },
    setParts(context, status) {
      context.commit('SET_PARTS', status);
    },
    setRegistrations(context, status) {
      context.commit('REGISTRATIONS', status);
    },
    getResvList(context, status) {
      let room = {};
      let time = {};
      let clinicCode = '';
      if (localStorage.getItem('clinicCode')) {
        clinicCode = localStorage.getItem('clinicCode');
      }
      if (localStorage.getItem(`room_${clinicCode}`)) {
        room = JSON.parse(localStorage.getItem(`room_${clinicCode}`));
      }
      if (localStorage.getItem(`time_${clinicCode}`)) {
        time = JSON.parse(localStorage.getItem(`time_${clinicCode}`));
      }
      return new Promise((resolve, reject) => {
        const api = `/quick_resv`;
        context.commit('LOADING', true, { root: true });
        status.append('room_no', room.no);
        status.append('period', time.schlen);
        status.append('checkin', time.checkin);
        instance({
          method: 'post',
          url: api,
          data: status,
          headers: { clinic_code: clinicCode },
        })
          .then((res) => {
            if (res.data.status == 'error' || res.data.status == 'failed') {
              reject(res.data.msg);
            } else {
              context.commit('RESV_LIST', res.data.data);
              resolve(res);
            }
            context.commit('LOADING', false, { root: true });
          })
          .catch((err) => {
            context.commit('LOADING', false, { root: true });
            reject(err);
          });
      });
    },
    getRegistrations(context, status) {
      return new Promise((resolve, reject) => {
        const { data, clinicCode } = status;
        console.log('getRegistrations', data, clinicCode);
        const api = `/get_resvs`;
        context.commit('LOADING', true, { root: true });
        instance({
          method: 'post',
          url: api,
          data: data.fd,
          headers: { clinic_code: clinicCode },
        })
          .then((res) => {
            if (res.data.status == 'error') {
              context.commit('LOADING', false, { root: true });
              reject(res.data.msg);
            } else {
              context.commit('REGISTRATIONS', { registrations: res.data.data.registrations, clinicCode, });
              context.commit('LOADING', false, { root: true });
              resolve(res);
            }
          })
          .catch((err) => {
            context.commit('REGISTRATIONS', { registrations: [], clinicCode, });
            context.commit('LOADING', false, { root: true });
            if (err.response.data.status == 'failed') {
              resolve()
            }
            resolve()
          });
      });
    },
    sendResv(context, status) {
      return new Promise((resolve, reject) => {
        const { data, clinicCode } = status;
        const api = `/resvs`;
        context.commit('LOADING', true, { root: true });
        instance({
          method: 'post',
          url: api,
          data: data.fd,
          headers: { clinic_code: clinicCode },
        })
          .then((res) => {
            if (res.data.status == 'error') {
              context.commit('LOADING', false, { root: true });
              reject(res.data.msg);
            } else {
              context.commit('RESV', { data: data.resv, clinicCode, });
              context.commit('LOADING', false, { root: true });
              resolve(res);
            }
          })
          .catch((err) => {
            context.commit('LOADING', false, { root: true });
            if (err.response.data.status == 'failed') {
              reject(err.response.data.message);
            }
            reject(err);
          });
      });
    },
    cancelResv(context, status) {
      return new Promise((resolve, reject) => {
        const { data, clinicCode } = status;
        const api = `/resvs/${data.regsn}`;
        context.commit('LOADING', true, { root: true });
        instance({
          method: 'post',
          url: api,
          data: data.fd,
          headers: { clinic_code: clinicCode },
        })
          .then((res) => {
            if (res.data.status == 'error') {
              // dont remove tr
              context.commit('LOADING', false, { root: true });
              reject(res.data.msg);
            } else {
              // remove tr
              context.commit('RM_REGISTRATION', { data: data.regsn, clinicCode, });
              context.commit('LOADING', false, { root: true });
              resolve(res);
            }
          })
          .catch((err) => {
            context.commit('LOADING', false, { root: true });
            reject(err);
          });
      });
    },
  },
  mutations: {
    RESV_LIST(state, status) {
      state.resv_list = status;
    },
    // SET_CLINIC(state, status) {
    //   localStorage.setItem('clinic', JSON.stringify(status));
    // },
    SET_MED(state, status) {
      const { data, clinicCode } = status;
      localStorage.setItem(`med_${clinicCode}`, JSON.stringify(data));
    },
    SET_MED_BOOKING(state, status) {
      const { data, clinicCode } = status;
      localStorage.setItem(`med_booking_${clinicCode}`, JSON.stringify(data));
    },
    SET_MED_PART(state, status) {
      const { data, clinicCode } = status;
      localStorage.setItem(`med_part_${clinicCode}`, JSON.stringify(data));
    },
    SET_MED_PROBLEM(state, status) {
      const { data, clinicCode } = status;
      localStorage.setItem(`med_problem_${clinicCode}`, JSON.stringify(data));
    },
    SET_ROOM(state, status) {
      const { data, clinicCode } = status;
      localStorage.setItem(`room_${clinicCode}`, JSON.stringify(data));
    },
    SET_BOOKING(state, status) {
      const { data, clinicCode } = status;
      localStorage.setItem(`booking_${clinicCode}`, JSON.stringify(data));
    },
    SET_TIME(state, status) {
      const { data, clinicCode } = status;
      localStorage.setItem(`time_${clinicCode}`, JSON.stringify(data));
    },
    SET_NOTE(state, status) {
      const { data, clinicCode } = status;
      localStorage.setItem(`note_${clinicCode}`, data);
    },
    SET_PARTS(state, status) {
      const { data, clinicCode } = status;
      localStorage.setItem(`parts_${clinicCode}`, data);
    },
    REGISTRATIONS(state, status) {
      const { registrations, clinicCode } = status;
      localStorage.setItem(`registrations_${clinicCode}`, JSON.stringify(registrations));
      state.registrations = registrations;
    },
    RM_REGISTRATION(state, status) {
      const { data, clinicCode } = status;
      let newArr = [];
      const registrations = JSON.parse(localStorage.getItem(`registrations_${clinicCode}`));
      registrations.forEach((item) => {
        if (item.id !== data) {
          newArr.push(item);
        }
      });
      localStorage.setItem(`registrations_${clinicCode}`, JSON.stringify(newArr));
      // state.registrations = newArr;
    },
    RESV(state, status) {
      const { data, clinicCode } = status;
      localStorage.setItem(`resv_${clinicCode}`, JSON.stringify(data));
      state.resv = data;
    },
  },
  getters: {
    resv_list(state) {
      return state.resv_list;
    },
    // clinic() {
    //   return JSON.parse(localStorage.getItem('clinic'));
    // },
    medByC: () => (clinicCode) => {
      const med = localStorage.getItem(`med_${clinicCode}`);
      if (med && med !== 'undefined') {
        return JSON.parse(med);
      } else {
        return undefined;
      }
    },
    medPartByC: () => (clinicCode) => {
      const medPart = localStorage.getItem(`med_part_${clinicCode}`);
      if (medPart && medPart !== 'undefined') {
        return JSON.parse(medPart);
      } else {
        return undefined;
      }
    },
    medProblemByC: () => (clinicCode) => {
      const medProblem = localStorage.getItem(`med_problem_${clinicCode}`);
      if (medProblem && medProblem !== 'undefined') {
        return JSON.parse(medProblem);
      } else {
        return undefined;
      }
    },
    roomByC: () => (clinicCode) => {
      const room = localStorage.getItem(`room_${clinicCode}`);
      if (room) {
        return JSON.parse(room);
      } else {
        return {};
      }
    },
    bookingByC: () => (clinicCode) => {
      const booking = localStorage.getItem(`booking_${clinicCode}`);
      if (booking) {
        return JSON.parse(booking);
      } else {
        return {};
      }
    },
    timeByC: () => (clinicCode) => {
      const time = localStorage.getItem(`time_${clinicCode}`);
      if (time) {
        return JSON.parse(time);
      } else {
        return {};
      }
    },
    noteByC: () => (clinicCode) => {
      const note = localStorage.getItem(`note_${clinicCode}`);
      if (note) {
        return note;
      } else {
        return '';
      }
    },
    partsByC: () => (clinicCode) => {
      const parts = localStorage.getItem(`parts_${clinicCode}`);
      if (parts) {
        return parts.split(',');
      } else {
        return [];
      }
    },
    registrationsByC: () => (clinicCode) => {
      const registrations = localStorage.getItem(`registrations_${clinicCode}`);
      if (registrations) {
        return JSON.parse(registrations);
      } else {
        return {};
      }
    },
    resvByC: () => (clinicCode) => {
      const resv = localStorage.getItem(`resv_${clinicCode}`);
      if (resv) {
        return JSON.parse(resv);
      } else {
        return {};
      }
    },
  },
};
