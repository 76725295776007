<template>
  <div class="main">
    <Loading v-if="isLoading"></Loading>
    <div class="main__block">
      <div class="flex f-col jcs aic" v-if="cusType == 'nnp'">
        <div class="title">填寫資料</div>
        <div class="flex f-col jcs aic">
          <input
            v-model="tempUser.name"
            type="text"
            class="input__input"
            placeholder="請輸入姓名"
          />
        </div>
        <div class="flex f-col jcs aic">
          <input
            ref="BDinput"
            v-model="tempUser.birthday"
            type="text"
            class="input__input"
            :max="toDay"
            @focus="setBDinputToDate"
            placeholder="請輸入生日(西元年/月/日)"
          />
        </div>
        <div class="flex f-col jcs aic">
          <input
            v-model="tempUser.mobile"
            type="text"
            class="input__input"
            placeholder="請輸入手機號碼"
          />
        </div>
      </div>
      <div v-if="registrations.length > 0" class="title">
        <span v-if="customer.cusname">{{customer.cusname}}</span>
        <span v-else-if="newpatient.name">{{newpatient.name}}</span>
        您好，您已預約...
      </div>
      <div class="table-wrap mob-show">
        <table v-if="registrations.length > 0">
          <tbody>
            <tr v-for="reg in registrations" :key="reg.id">
              <td>
                <div v-if="isCancelDeadline(reg.date)" style="text-align: center;">
                  {{ online_cancel_day }}日內預約<br>已無法取消
                </div>
                <div v-else @click="cancelResv(reg.id)" class="btn btn-sm">
                  取消
                </div>
              </td>
              <td>
                <span
                  >日期：{{ reg.date }}({{ showWeekDay(reg.date) }})
                  {{
                    calCheckinTime(reg.date, reg.sch_time, reg.checkin)
                  }}</span
                >
                <span class="strong">項目：{{ reg.sch_note }}</span>
                <span>
                  <span v-if="clinic.name === '樂衍'">您預約的服務人員：{{ reg.staff.name }}({{ reg.staff.sex | gender }})</span>
                  <span v-else-if="dental_is_enable == '2'">
                    <span v-if="reg.sch_note.indexOf('除毛') > -1">
                      醫師：{{ reg.staff.name }}({{ reg.staff.sex | gender }})
                    </span>
                    <span v-else-if="reg.sch_note.indexOf('果酸') > -1">
                      美容師：
                      {{ reg.staff.name }}({{ reg.staff.sex | gender }})
                    </span>
                    <span v-else>
                      醫師：{{ reg.staff.name }}({{ reg.staff.sex | gender }})
                    </span>
                  </span>
                  <span v-else-if="dental_is_enable == '3'">
                    治療師：
                    {{ reg.staff.name }}({{ reg.staff.sex | gender }})
                  </span>
                  <span v-else>
                    醫師：
                    {{ reg.staff.name }}({{ reg.staff.sex | gender }})
                  </span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="!no_remaining_times && ((registrations.length > 0 && cusType !== 'np') || cusType == 'nnp' || cusType === 'cus')" style="margin-top: 60px;">
        <div class="title" style="text-align: center;">選擇預約項目</div>
        <div class="flex f-row jcsb aic services">
          <div v-for="(room, index) in filterRooms" :key="index">
            <div
              @click="chooseService(room)"
              class="btn btn-lg"
            >
              {{ room.name }}
            </div>
          </div>
          <div
            v-for="booking in bookings" :key="`booking_${booking.id}`"
            class="btn btn-lg" :style="{display: rooms.find(item => item.no == booking.room_no) ? 'flex' : 'none'}"
            @click="chooseService(rooms.find(item => item.no == booking.room_no), booking.name, booking)">
              {{ booking.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '../components/Loading.vue';
export default {
  name: 'ChooseService',
  components: {
    Loading,
  },
  data() {
    return {
      showRoom: 
      [
        '果酸',
        '除毛',
        '雷美',
        '雷美一',
        '增生門診',
        '植牙',
        '牙周',
        '止鼾諮詢門診',
        '一般治療',
        '矯正門診',
        '假牙贗復',
        '洗牙',
        '檢查',
        '塗氟',
        '矯正諮詢',
        '植牙諮詢',
      ],
      registrations: {},
      tempUser: {
        name: '',
        birthday: '',
        mobile: ''
      }
    };
  },
  filters: {
    gender(num = 0) {
      if (num === 0) {
        return '女';
      } else if (num === 1) {
        return '男';
      } else {
        return '';
      }
    },
  },
  methods: {
    showWeekDay(date) {
      const week = ['日', '一', '二', '三', '四', '五', '六'];
      const d = new Date(date);
      return week[d.getDay()];
    },
    setBDinputToDate() {
      this.$refs.BDinput.setAttribute('type', 'date');
    },
    checkBDDate() {
      return new Date(this.tempUser.birthday) > new Date('1911-01-01');
    },
    checkClinic() {
      if (this.$route.query.clinicCode) {
        localStorage.setItem('clinicCode', this.$route.query.clinicCode);
      } else {
        alert('診所代碼錯誤,請重新登入！');
        this.backToLogin();
      }
    },
    backToLogin() {
      const clinicCode = this.$route.query.clinicCode;
      this.$router.push(`/login?clinicCode=${clinicCode}`);
    },
    isCancelDeadline(date) {
      // 判斷date-今天得出的天數，是否大於等於online_cancel_day
      const today = new Date();
      const dd = today.getDate();
      const mm = today.getMonth() + 1; //January is 0!
      const yyyy = today.getFullYear();
      const dateStr = `${date} 00:00:00`;
      const todayStr = `${yyyy}-${mm}-${dd} 00:00:00`;
      const diff = Math.floor((new Date(dateStr) - new Date(todayStr)) / (1000 * 60 * 60 * 24));
      
      if (diff <= this.online_cancel_day) {
        return true;
      } else {
        return false;
      }
    },
    async cancelResv(regsn) {
      try {
        const clinicCode = this.$route.query.clinicCode;
        if (confirm('確定要取消預約？')) {
          const fd = new FormData();
          fd.append('Authorization', this.token);
          fd.append('_method', 'delete');
          fd.append('system_by', 'online');
          await this.$store.dispatch('resvModule/cancelResv', {
            data: {
              fd: fd,
              regsn: regsn,
            },
            clinicCode,
          });
          this.registrations = JSON.parse(
            localStorage.getItem(`registrations_${clinicCode}`)
          );
          if(this.registrations.length == 0 && this.cusType == 'np') {
            this.backToLogin();
          }
        }
      } catch (e) {
        const message = e.response.data.msg;
        alert(message);
        console.log(e.response.data);
      }
    },
    calCheckinTime(ddate, sch_time, checkin = 0) {
      const dd = ddate.split('-');
      const y = dd[0];
      const m = dd[1];
      const d = dd[2];

      const tt = sch_time.split(':');
      const h = tt[0];
      const mm = tt[1];
      let dt = new Date(y, m, d, h, mm, 0);
      dt.setMinutes(dt.getMinutes() - checkin);

      const hour = this.padLeft(dt.getHours(), 2, '0');
      const min = this.padLeft(dt.getMinutes(), 2, '0');
      const checkinTime = `${hour}:${min}`;
      return checkinTime;
    },
    padLeft(str, lenght, padstr = '0') {
      str = '' + str;
      return str.length >= lenght
        ? str
        : new Array(lenght - str.length + 1).join(padstr) + str;
    },
    formValidate() {
      return new Promise((resolve, reject) => {
        if(this.tempUser.name.length < 1) {
          reject('請輸入姓名');
        }
        if(this.tempUser.birthday == '' || !this.checkBDDate()) {
          reject('生日未輸入或格式錯誤(西元年/月/日)');
        }
        if(this.tempUser.mobile == '' || this.tempUser.mobile.length !== 10) {
          reject('手機號碼格式錯誤');
        }
        resolve();
      })
    },
    async chooseService(room, medBeauty = null, medBooking = null) {
      const clinicCode = this.$route.query.clinicCode;
      await this.$store.dispatch('authModule/setNnp', { nnp: this.tempUser, clinicCode, });
      try {
        if(this.cusType == 'nnp'){
          await this.formValidate();
        }
        await this.$store.dispatch('resvModule/setRoom', { data: room, clinicCode, });
        await this.$store.dispatch('resvModule/setMed', { data: undefined, clinicCode, });
        await this.$store.dispatch('resvModule/setMedPart', { data: undefined, clinicCode, });
        await this.$store.dispatch('resvModule/setMedProblem', { data: undefined, clinicCode, });
        if (room.name == '除毛' && !medBooking) { // 膚適美 only
          await this.$store.dispatch('resvModule/setNote', { data: '除毛', clinicCode, });
          this.$router.push(`/choose-part?clinicCode=${clinicCode}`);
        } else if (medBeauty == '雷射' && medBooking) { // 膚適美 OR 木木日安 only
          await this.$store.dispatch('resvModule/setMed', { 
            data: {
              type: '雷射',
              id: medBooking.id,
              name: '',
            }, 
            clinicCode,
          });
          await this.$store.dispatch('resvModule/setBooking', { data: medBooking, clinicCode, });
          this.$router.push(`/choose-laser?clinicCode=${clinicCode}`);
        } else if (medBooking) {
          // 有子預約項目
          await this.$store.dispatch('resvModule/setMed', { 
            data: {
              type: medBooking.name,
              id: medBooking.id,
              name: medBooking.name,
            }, 
            clinicCode,
          });
          await this.$store.dispatch('resvModule/setBooking', { data: medBooking, clinicCode, });
          this.$router.push(`/choose-resv-item?clinicCode=${clinicCode}`);
        } else {
          // 無子預約項目直接預約診間
          await this.$store.dispatch('resvModule/setTime', {
            data: {
              schlen: room.schlen,
              checkin: room.checkin,
            },
            clinicCode,
          });
          await this.$store.dispatch('resvModule/setNote', { data: room.name, clinicCode, });
          this.$router.push(`/choose-time?clinicCode=${clinicCode}`);
        }
      } catch (e) {
        alert(e);
        console.log(e);
      }
    },
  },
  computed: {
    ...mapGetters(['isLoading', 'tokenByC']),
    ...mapGetters('authModule', [
      'roomsByC', 
      'clinicByC', 
      'dentalIsEnableByC', 
      'customerByC', 
      'newpatientByC',
      'bookingsByC',
      'resvHoursByC',
      'onlineCancelDay',
      'onlineLimits',
    ]),
    ...mapGetters('resvModule', ['resvsByC']),
    resvs() {
      const clinicCode = this.$route.query.clinicCode;
      return this.resvsByC(clinicCode);
    },
    token() {
      const clinicCode = this.$route.query.clinicCode;
      return this.tokenByC(clinicCode);
    },
    rooms() {
      const clinicCode = this.$route.query.clinicCode;
      return this.roomsByC(clinicCode);
    },
    clinic() {
      const clinicCode = this.$route.query.clinicCode;
      return this.clinicByC(clinicCode);
    },
    online_cancel_day() {
      const clinicCode = this.$route.query.clinicCode;
      return this.onlineCancelDay(clinicCode);
    },
    online_limits() {
      const clinicCode = this.$route.query.clinicCode;
      return this.onlineLimits(clinicCode);
    },
    no_remaining_times() {
      let no_remaining_times = false;
      if (this.online_limits && this.online_limits.length > 0) {
        // 檢查array內是否任一筆有item.times-items-used > 0 (剩餘次數)
        no_remaining_times = this.online_limits.some(item => item.used >= item.times);
      }
      return no_remaining_times;
    },
    hasMed() {
      let hasMed = false;
      hasMed = this.rooms.some(item => {
        if (item.disable_resv == 0 && (item.name.indexOf('雷美') > -1 || item.name.indexOf('雷美一') > -1)) {
          return true;
        }
      });
      return hasMed;
    },
    filterRooms() {
      const filterRooms = [];
      this.rooms.forEach((item) => {
        const resvItems = this.bookings.find(b => b.room_no == item.no);
        console.log(resvItems);
        if(item.disable_resv == 0 && !resvItems) {
          filterRooms.push(item);
        }
      });
      return filterRooms;
    },
    dental_is_enable() {
      const clinicCode = this.$route.query.clinicCode;
      return this.dentalIsEnableByC(clinicCode);
    },
    bookings() {
      const clinicCode = this.$route.query.clinicCode;
      return this.bookingsByC(clinicCode);
    },
    resv_hours() {
      const clinicCode = this.$route.query.clinicCode;
      return this.resvHoursByC(clinicCode);
    },
    customer() {
      const clinicCode = this.$route.query.clinicCode;
      return this.customerByC(clinicCode);
    },
    newpatient() {
      const clinicCode = this.$route.query.clinicCode;
      return this.newpatientByC(clinicCode);
    },
    cusType() {
      let cusType = 'nnp';
      if(Object.keys(this.customer).length > 0) {
        cusType = 'cus';
      }
      if(Object.keys(this.newpatient).length > 0) {
        cusType = 'np';
      }
      return cusType;
    },
    toDay() {
      const today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1; //January is 0!
      let yyyy = today.getFullYear();
      if (dd < 10) {
        dd = '0' + dd;
      }
      if (mm < 10) {
        mm = '0' + mm;
      } 
      return `${yyyy}-${mm}-${dd}`; 
    },
  },
  async created() {
    await this.checkClinic();
    window.scrollTo(0, 0);
    const clinicCode = this.$route.query.clinicCode;
    const fd = new FormData();
    fd.append('Authorization', this.token);
    await this.$store.dispatch('resvModule/getRegistrations', { data: { fd }, clinicCode});
    this.registrations = JSON.parse(localStorage.getItem(`registrations_${clinicCode}`));
    const nnp = JSON.parse(localStorage.getItem(`nnp_${clinicCode}`));
    if(Object.keys(nnp).length > 0) {
      const { name, birthday, mobile } = nnp; 
      this.tempUser.name = name;
      this.tempUser.birthday = birthday;
      this.tempUser.mobile = mobile;
    }
  },
};
</script>
<style>
.services{
  flex-wrap: wrap;
  gap: 15px;
}
</style>
