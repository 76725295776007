import axios from 'axios';
import https from 'https';
const httpsAgent = new https.Agent({
  rejectUnauthorized: false,
});
const instance = axios.create({
  baseURL: `${process.env.VUE_APP_APIURL}/api`,
  httpsAgent,
});

export default {
  namespaced: true,
  state: {
  },
  actions: {
    staffLogin(context, status) {
      return new Promise((resolve, reject) => {
        const { data } = status;

        const api = `/airlee/auth/login`;
        context.commit('LOADING', true, { root: true });
        instance({
          method: 'post',
          url: api,
          data: data,
        })
          .then((res) => {
            console.warn(res);
            if (res.data.status == 'error') {
              reject(res.data.msg);
            } else {
              const { token, staff } = res.data;
              context.commit('AIRLEE_TOKEN', token, { root: true });
              context.commit('AIRLEE_STAFF', staff, { root: true });

              resolve(res);
            }
            context.commit('LOADING', false, { root: true });
          })
          .catch((err) => {
            console.log(err)
            if (err.response.data.status == 'failed') {
              err = new Error(err.response.data.message);
            }
            context.commit('LOADING', false, { root: true });
            reject(err);
          });
      });
    },
    tokenValidate(context) {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token');
        if (!token) {
          reject(new Error('請重新登入'));
        }
        const api = `/airlee/auth`;
        context.commit('LOADING', true, { root: true });
        instance({
          method: 'post',
          url: api,
          headers: { Authorization: `${token}` },
        })
          .then((res) => {
            console.warn(res);
            if (res.data.status == 'error') {
              reject(res.data.msg);
            } else {
              resolve(res);
            }
            context.commit('LOADING', false, { root: true });
          })
          .catch((err) => {
            console.log(err)
            if (err.response.data.status == 'failed') {
              err = new Error(err.response.data.message);
            }
            context.commit('LOADING', false, { root: true });
            reject(err);
          });
      });
    }
  },
  mutations: {
  },
  getters: {
  },
};
