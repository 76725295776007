<template>
  <div class="main login">
    <Loading v-if="isLoading"></Loading>
    <div class="main__block">
      <div class="title">
        選擇分院
      </div>
      <div class="flex f-col jcs aic">
        <!-- 分院列表下拉 -->
        <select v-model="clinic">
          <option value="" disabled selected>請選擇分院</option>
          <option v-for="(clinic, index) in clinicList" :key="index" :value="clinic">{{ clinic.name }}</option>
        </select>
        <div @click="chooseClinic" class="btn">確認</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '../../components/Loading.vue';
import router from '../../router';
export default {
  name: 'AirLeeChooseClinic',
  components: {
    Loading,
  },
  data() {
    return {
      tempUser: {
        sfno: '',
        sfpasswd: '',
      },
      clinic: '',
      clinicList: {},
    };
  },
  methods: {
    async getAirleeClinicList() {
      try {
        this.clinicList = await this.$store.dispatch('airleeClinicModule/getAirLeeClinicList');
      } catch (e) {
        console.log(e);
        if (e.response) {
          if (e.response.status === 401) {
            alert('請重新登入');
            router.push('./staff/login');
          } else {
            alert(e.response.data.message);
          }
        } else {
          alert(e.message);
        }
      }
    },
    async chooseClinic() {
      try {
        if (!this.clinic) {
          alert('請選擇分院');
          return;
        }
        router.push(`./customer/login?clinicCode=airlee_${this.clinic.clinic_id}`);
      } catch (e) {
        alert(e);
      }
    },
    async init() {
      try {
        await this.getAirleeClinicList();
        console.log('init');
      } catch (e) {
        alert(e);
      }
    },
  },
  computed: {
    ...mapGetters(['isLoading']),
  },
  mounted() {},
  async created() {
    try {
      await this.init();
    } catch (e) {
      alert(e);
    }
  },
};
</script>
<style lang="scss" scoped>
.btn {
  margin-bottom: 20px;
}
.statement {
  width: 100%;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  border: solid 1px rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 0.2);
  span {
    color: #fff !important;
    letter-spacing: 3px;
    a {
      color: #178fac;
    }
  }
}
</style>
