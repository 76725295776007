<template>
  <div class="main login">
    <Loading v-if="isLoading"></Loading>
    <div class="main__block">
      <div class="title">
        <span>愛爾麗線上預約系統(員工登入)</span>
      </div>
      <div class="title-mob">
        <span>愛爾麗線上預約系統(員工登入)</span>
      </div>
      <div class="flex f-col jcs aic">
        <div class="flex f-col jcs aic">
          <input
            v-model="tempUser.sfno"
            type="text"
            class="input__input"
            placeholder="請輸入員工編號"
            @keyup.enter="login"
          />
        </div>
        <div class="flex f-col jcs aic">
          <input
            v-model="tempUser.sfpasswd"
            type="password"
            class="input__input"
            placeholder="請輸入密碼"
            @keyup.enter="login"
          />
        </div>
        <div @click="login" class="btn">確認</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '../../components/Loading.vue';
export default {
  name: 'AirLeeStaffLogin',
  components: {
    Loading,
  },
  data() {
    return {
      tempUser: {
        sfno: '',
        sfpasswd: '',
      },
      clinic: {},
      clinicList: {},
    };
  },
  methods: {
    async getSettings() {
      try {
        const clinicCode = this.$route.query.clinicCode;
        await this.$store.dispatch('authModule/getSettings', clinicCode);
        this.clinic = JSON.parse(localStorage.getItem(`clinic_${clinicCode}`));
      } catch (e) {
        alert(e);
        console.log(e);
      }
    },
    checkClinic() {
      const vm = this;
      return new Promise((resolve, reject) => {
        const clinicCode = this.$route.query.clinicCode;
        if (clinicCode) {
          localStorage.setItem('clinicCode', clinicCode);
          localStorage.removeItem(`token_${clinicCode}`);
          resolve();
        } else {
          localStorage.removeItem('clinic');
          vm.clinic = {};
          reject('請於網址處輸入診所代碼');
        }
      });
    },
    formValidate() {
      return new Promise((resolve, reject) => {
        const error = new Error();
        if(!this.tempUser.sfno.trim()) {
          error.message = '請輸入員工編號';
          reject(error);
        }
        if (!this.tempUser.sfpasswd.trim()) {
          error.message = '請輸入密碼';
          reject(error);
        }
        resolve();
      })
    },
    async login() {
      try {
        await this.formValidate();
        const data = {
          sfno: this.tempUser.sfno,
          sfpasswd: this.tempUser.sfpasswd,
        }
        await this.$store.dispatch('airleeAuthModule/staffLogin', { data, });
        alert('登入成功');
        this.$router.push(`/airlee/choose-clinic`);
      } catch (e) {
        console.log(e);
        alert(e.message);
      }
    },
    async init() {
      try {
        localStorage.clear();
        console.log('init');
      } catch (e) {
        alert(e);
      }
    },
  },
  computed: {
    ...mapGetters(['isLoading']),
    ...mapGetters('authModule', ['onlineResvNp']),
  },
  mounted() {},
  async created() {
    try {
      await this.init();
    } catch (e) {
      alert(e);
    }
  },
};
</script>
<style lang="scss" scoped>
.btn {
  margin-bottom: 20px;
}
.statement {
  width: 100%;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  border: solid 1px rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 0.2);
  span {
    color: #fff !important;
    letter-spacing: 3px;
    a {
      color: #178fac;
    }
  }
}
</style>
