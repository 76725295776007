<template>
  <div class="main login">
    <Loading v-if="isLoading"></Loading>
    <div class="main__block" v-if="!(online_resv_np == 0 && online_resv_op == 0)">
      <div class="title" v-if="Object.values(clinic).length > 0">
        <span v-if="clinic && clinic.name.indexOf('木木日安') > -1" id="clinicName">果酸護理</span
        >
        <span v-if="clinic && clinic.name.indexOf('木木日安') == -1" id="clinicName">{{ clinic.name }}</span
        >線上快速預約
      </div>
      <div class="title-mob" v-if="Object.values(clinic).length > 0">
        <span v-if="clinic && clinic.name.indexOf('木木日安') > -1" id="clinicName">果酸護理</span>
        <span v-if="clinic && clinic.name.indexOf('木木日安') == -1" id="clinicName">{{ clinic.name }}</span>
        <span>線上快速預約</span>
      </div>
      <div class="title" v-if="Object.values(clinic).length < 1">
        線上快速預約
      </div>
      <div class="flex f-col jcs aic" v-if="Object.values(clinic).length > 0">
        <div class="flex f-col jcs aic">
          <input
            v-model="tempUser.cusid"
            type="text"
            class="input__input"
            :placeholder="clinic.name == '樂衍' ? '請輸入機構代碼' : '請輸入身分證'"
          />
        </div>
        <div class="flex f-col jcs aic" v-if="online_resv_np > 0">
          <input
            v-model="tempUser.cusmob"
            type="text"
            class="input__input"
            placeholder="請輸入手機號碼"
          />
        </div>
        <div class="flex f-col jcs aic">
          <input
            @keypress.enter="login"
            v-model="tempUser.captcha.content"
            type="text"
            class="input__input"
            placeholder="請輸入驗證碼(不分大小寫)"
          />
          <div class="input__valid" @click="getCaptcha">
            <img :src="captcha.image" class="" alt="" />
            <span class="muted">點擊圖片可更換驗證碼</span>
          </div>
        </div>
        <div @click="login" class="btn">確認</div>
          <div class="statement" v-if="clinic.name == '木木日安'"> <!-- 復興館 -->
            <span style="font-weight: 700;font-size: 1.2rem;margin-bottom: 10px"
              >預約政策 :
            </span>
            <span>
              1.本系統提供您 線上預約 以及 線上取消預約 兩個功能
            </span>
            <span style="margin-bottom: 10px"
              >2.果酸護理每週限約1次 醫療級粉刺處理每週限約1次
            </span>
            <span>
              3.如欲取消預約，請您登入系統後，查詢您的預約，即可取消，因系統無法取消24小時內的預約，請您務必與診所聯繫
            </span>
            <span>
              4.如果您想預約的治療項目不在表列當中, 或有其他特殊需求。請您在官方LINE: <a target="_blank" href="https://line.me/ti/p/~@mu27787178">@mu27787178</a>留言, 或致電客服<a :href="'tel:+886-'+clinic.tel">{{ clinic.tel }}</a>由專人立即為您服務
            </span>
          </div>
          <div class="statement" v-if="clinic.name == '木木日安-敦南館'"> <!-- 敦南館 -->
            <span style="font-weight: 700;font-size: 1.2rem;margin-bottom: 10px"
              >預約政策 :
            </span>
            <span>
              1.本系統提供您 線上預約 以及 線上取消預約 兩個功能
            </span>
            <span style="margin-bottom: 10px">
              2.線上預約功能不適用於初診，請您致電診所端
              <a :href="'tel:+886-'+clinic.tel">{{ clinic.tel }}</a>
              預約
            </span>
            <span style="margin-bottom: 10px"
              >3.果酸護理每週限約1次   醫療級粉刺處理每週限約1次
              <a :href="'tel:+886-'+clinic.tel">{{ clinic.tel }}</a>
            </span>
            <span>
              4.預約後要取消，請您登入系統後，查詢您的預約，即可取消，系統無法取消24小時內的預約，請您與診所聯繫
            </span>
          </div>
          <div class="statement" v-else-if="clinic.name === '膚適美'">
            <span style="font-weight: 700;font-size: 1.2rem;margin-bottom: 10px"
              >預約政策 :
            </span>
            <span>
              1.預約 及 取消預約請先登入系統
            </span>
            <span style="margin-bottom: 10px">
              2.初診者請使用電話預約
              <a :href="'tel:+886-'+clinic.tel">{{ clinic.tel }}</a>
              ，系統不提供初診掛號
            </span>
            <span style="margin-bottom: 10px"
              >3.雷射除毛因治療安全考量，恕不提供多重項目預約
            </span>
            <span style="margin-bottom: 10px"
              >4.玻尿酸舒顏翠等填充物，及音電波因程序問題，請您在官方LINE: <a target="_blank" href="https://line.me/ti/p/~@fsm601">@fsm601</a>留言, 或致電客服<a :href="'tel:+886-'+clinic.tel">{{ clinic.tel }}</a>由專人立即為您服務
            </span>
            <span>
              5.請注意 : 雷射除毛兩周內限預約1次，果酸保養兩周內限預約1次
            </span>
            <span>
              6.如果您想預約的治療項目不在表列當中, 或有其他特殊需求。請您在官方LINE: <a target="_blank" href="https://line.me/ti/p/~@fsm601">@fsm601</a>留言, 或致電客服<a :href="'tel:+886-'+clinic.tel">{{ clinic.tel }}</a>由專人立即為您服務
            </span>
          </div>
          <div class="statement" v-else-if="clinic.name === '超全能'">
            <span style="font-weight: 700;font-size: 1.2rem;margin-bottom: 10px"
              >LINE線上預約政策 :
            </span>

            <span>
              1.第一次看診無法使用系統線上預約，初診請打電話預約:<a :href="'tel:+886-'+clinic.tel">{{ clinic.tel }}</a>。
            </span>
            <span style="margin-bottom: 10px">
              2.系統線上預約目前只開放30分鐘的增生門診病患。
            </span>
            <span style="margin-bottom: 10px">
              3.整合門診、其他物理治療、多重治療項目預約請在LINE上留言或電話預約。
            </span>
            <span style="margin-bottom: 10px">
              4.請注意:預約增生門診請與前次看診相隔3-4週，若不清楚請詢問診所人員。
            </span>
            <span style="margin-bottom: 10px">
              5.線上預約完成後請如期準時到診，勿隨意取消或改約，若突發狀況無法到診，請於LINE上回覆約診狀況，謝謝您的配合。
            </span>
            <span>
              6.其他約診相關問題，可於LINE 上留言，或來電諮詢: <a :href="'tel:+886-'+clinic.tel">{{ clinic.tel }}</a>。
            </span>
          </div>
          <div class="statement" v-else-if="clinic.name === '博田國際牙醫診所'">
            <span style="font-weight: 700;font-size: 1.2rem;margin-bottom: 10px"
              >預約政策 :
            </span>
            <span>
              1.預約 及 取消預約請先登入系統
            </span>
            <span style="margin-bottom: 10px">
              2.請注意 : 每項預約一周內限預約1次
            </span>
          </div>
          <div class="statement" v-else-if="clinic.name === '潔宇牙醫診所'">
            <span style="font-weight: 700;font-size: 1.2rem;margin-bottom: 10px"
              >預約政策 :
            </span>
            <span>
              1.預約 及 取消預約請先登入系統
            </span>
            <span>
              2.目前僅提供洗牙、檢查、塗氟、矯正諮詢、植牙諮詢的療程線上預約，其他療程暫不開放，如果需要請您致電診所<a :href="'tel:+886-'+clinic.tel">{{ clinic.tel }}</a>
            </span>
            <span style="margin-bottom: 10px">
              3.請注意：預約當日請務必準時前來，如需改約請提前告知，無故未到累計兩次將無法再做預約
            </span>
          </div>
          <div class="statement" v-else-if="clinic.name === '小宙醫學診所'">
            <span style="font-weight: 700;font-size: 1.2rem;margin-bottom: 10px"
              >預約政策 :
            </span>
            <span>
              1.我們CMslim治療部位：腹部，肩部，背部，上臂，大腿，骨盆底肌。一共六個部位。
            </span>
            <span style="margin-bottom: 10px">
              2.同一個部位，一天最多一次，一週最多三次。
            </span>
          </div>
          <div class="statement" v-else-if="clinic.name === '艾比四季'">
            <span style="font-weight: 700;font-size: 1.2rem;margin-bottom: 10px"
              >預約政策 :
            </span>
            <span>
              *預約系統僅供隔天預約使用，如有當日就診需求，歡迎直接來電，讓我們協助您
            </span>
            <span>
              *預約成功後，會有專人致電聯繫，請留意診所來電
            </span>
            <span>
              *可供線上預約的時段會隨時更新，有其他時段需求歡迎來電
            </span>
            <span style="margin-bottom: 10px">
              *艾比四季牙醫診所 電話：<a :href="'tel:+886-'+clinic.tel">{{ clinic.tel }}</a>
            </span>
          </div>
          <div class="statement" v-else-if="clinic.name === '挺立康'">
            <span style="font-weight: 700;font-size: 1.2rem;margin-bottom: 10px"
              >預約政策 :
            </span>
            <span>
              *此線上預約功能僅提供曾經有看診過的患者使用，如果是新患者歡迎來電諮詢喔！謝謝
            </span>
            <span style="margin-bottom: 10px">
              *聯絡電話：<a :href="'tel:+886-'+clinic.tel">{{ clinic.tel }}</a>
            </span>
          </div>
          <div v-else class=""></div>
      </div>
    </div>
    <div class="main__block" v-else>
      <div class="title">
        無法使用線上快速預約，請來電洽詢
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '../components/Loading.vue';
import { verifyId } from '../js/helper';
export default {
  name: 'Login',
  components: {
    Loading,
  },
  data() {
    return {
      tempUser: {
        cusid: '',
        cusmob: '',
        captcha: {
          content: '',
        },
      },
      clinic: {},
      clinicList: {},
    };
  },
  methods: {
    async getSettings() {
      try {
        const clinicCode = this.$route.query.clinicCode;
        await this.$store.dispatch('authModule/getSettings', clinicCode);
        this.clinic = JSON.parse(localStorage.getItem(`clinic_${clinicCode}`));
      } catch (e) {
        alert(e);
        console.log(e);
      }
    },
    async getCaptcha() {
      try {
        await this.$store.dispatch('authModule/getCaptcha');
      } catch (e) {
        alert(e);
        console.log(e);
      }
    },
    checkClinic() {
      const vm = this;
      return new Promise((resolve, reject) => {
        const clinicCode = this.$route.query.clinicCode;
        if (clinicCode) {
          localStorage.setItem('clinicCode', clinicCode);
          localStorage.removeItem(`token_${clinicCode}`);
          resolve();
        } else {
          localStorage.removeItem('clinic');
          vm.clinic = {};
          reject('請於網址處輸入診所代碼');
        }
      });
    },
    formValidate() {
      return new Promise((resolve, reject) => {
        const error = new Error();
        if(this.clinic.name !== '樂衍' && !verifyId(this.tempUser.cusid)) {
          error.message = '身分證格式錯誤';
          reject(error);
        }
        if(this.online_resv_np > 0 && (this.tempUser.cusmob == '' || this.tempUser.cusmob.length !== 10)) {
          error.message = '手機號碼格式錯誤';
          reject(error);
        }
        if(this.tempUser.captcha.content.length < 1) {
          error.message = '請輸入驗證碼';
          reject(error);
        }
        resolve();
      })
    },
    async login() {
      try {
        await this.formValidate();
        await this.checkClinic();
        const fd = await this.makeFormData();
        const clinicCode = this.$route.query.clinicCode;
        const justNp = this.online_resv_np == 2 || false;
        const justOp = this.online_resv_op >= 2 || false;
        await this.$store.dispatch('authModule/login', { data: fd, clinicCode, justNp, justOp });
        this.$router.push(`/choose-service?clinicCode=${clinicCode}`);
      } catch (e) {
        console.log(e);
        alert(e.message);
        this.getCaptcha();
      }
    },
    makeFormData() {
      const fd = new FormData();
      fd.append('cusid', this.tempUser.cusid);
      if(this.tempUser.cusmob) {
        fd.append('mobile', this.tempUser.cusmob);
      }
      fd.append('captcha[key]', this.captcha.key);
      fd.append('captcha[content]', this.tempUser.captcha.content);
      return fd;
    },
    async init() {
      try {
        // localStorage.clear();
        await this.checkClinic();
        await this.getSettings();
        await this.getCaptcha();
        console.log('init');
      } catch (e) {
        alert(e);
      }
    },
  },
  computed: {
    ...mapGetters(['isLoading']),
    ...mapGetters('authModule', ['captcha', 'onlineResvNp', 'onlineResvOp']),
    online_resv_np() {
      const clinicCode = this.$route.query.clinicCode;
      return this.onlineResvNp(clinicCode);
    },
    online_resv_op() {
      const clinicCode = this.$route.query.clinicCode;
      return this.onlineResvOp(clinicCode);
    },
  },
  mounted() {},
  async created() {
    try {
      // this.$store.dispatch('setLoading', true, { root: true });
      // setTimeout(() => {
      //   this.$store.dispatch('setLoading', false, { root: true });
      //   this.init();
      // }, 1000);
      await this.init();
      // await localStorage.setItem('registrations', JSON.stringify([]));
    } catch (e) {
      alert(e);
    }
  },
};
</script>
<style lang="scss" scoped>
.btn {
  margin-bottom: 20px;
}
.statement {
  width: 100%;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  border: solid 1px rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 0.2);
  span {
    color: #fff !important;
    letter-spacing: 3px;
    a {
      color: #178fac;
    }
  }
}
</style>
