import axios from 'axios';
import https from 'https';
const httpsAgent = new https.Agent({
  rejectUnauthorized: false,
});
const instance = axios.create({
  baseURL: `${process.env.VUE_APP_APIURL}/api`,
  httpsAgent,
});

export default {
  namespaced: true,
  state: {
  },
  actions: {
    getAirLeeClinicList(context) {
      return new Promise((resolve, reject) => {
        const api = `/airlee/clinic`;
        context.commit('LOADING', true, { root: true });
        instance({
          method: 'get',
          url: api,
          headers: { Authorization: `${localStorage.getItem('token')}` },
        })
          .then((res) => {
            const { clinic } = res.data;
            console.log('clinic', clinic);
            
            context.commit('LOADING', false, { root: true });
            resolve(clinic);
          })
          .catch((err) => {
            context.commit('LOADING', false, { root: true });
            reject(err);
          });
      });
    },
  },
  mutations: {
  },
  getters: {
  },
};
